/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { kebabCase } from './kebabCase';

export type WarrantyIcon = {
  alt: string;
  src: string;
};

export const getWarrantyBadge = (warranty: string): WarrantyIcon => {
  const iconNumber = warranty.split(' ')?.[0];
  const iconName = isNaN(Number(warranty?.split(' ')[0])) ? kebabCase(warranty) : iconNumber;

  return {
    src: `/images/warranty/${iconName}.svg`,
    alt: warranty?.replace(/-/g, ' ').toLowerCase(),
  };
};
