/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useState, useRef, useEffect, useCallback } from 'react';
import cx from 'classnames';
import { Popper, PopperHeader, PopperBody } from '../Popper/Popper';
import Image from '@/components/NextImage';
import { infoIcon } from '../../../constants/images';
import { eventConstants } from '../../../constants/event';
import { Button } from '@/components/Button';
import azCommonStyles from '../../../theme/globals.module.scss';
import type { InfoToolTipProps } from './types';
import styles from './styles.module.scss';
import { Icon, View } from '@az/starc-ui';
import { InfoCircle } from '@az/starc-ui-icons';

function TooltipIcon({
  iconSizePx,
  iconSrc,
}: {
  iconSizePx?: number;
  iconSrc?: { alt: string; src: string };
}) {
  if (iconSizePx) {
    return (
      <View
        height={`${iconSizePx}px`}
        width={`${iconSizePx}px`}
        align="center"
        justify="space-around"
      >
        <Icon
          svg={InfoCircle}
          size={(iconSizePx * 0.67) / 4}
          attributes={{ style: { color: '#6E6E6E' } }}
        />
      </View>
    );
  }
  return (
    <Image
      className={styles.tooltipImage}
      src={iconSrc ? iconSrc.src : infoIcon.src}
      alt={iconSrc ? iconSrc.alt : infoIcon.alt}
      width={24}
      height={24}
    />
  );
}

const InfoToolTip = ({
  header,
  body,
  isCorePricePadding = false,
  placement = 'bottom',
  classes = {},
  hideCloseIcon = false,
  isCustomElement = false,
  variant = 'ghost',
  customElement,
  'data-testid': dataTestId,
  setInfoToolTipIsOpen,
  iconSrc,
  divStyle,
  iconSizePx,
}: InfoToolTipProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const infoIconRef = useRef<any>(null);

  const openTooltip = () => {
    setIsOpen(true);
    if (setInfoToolTipIsOpen !== undefined) {
      setInfoToolTipIsOpen(true);
    }
  };

  const closeTooltip = useCallback(() => {
    setIsOpen(false);
    if (setInfoToolTipIsOpen !== undefined) {
      setInfoToolTipIsOpen(false);
    }
  }, [setInfoToolTipIsOpen]);

  const handleOnMouseClick = () => {
    isOpen ? closeTooltip() : openTooltip();
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (infoIconRef.current && !infoIconRef.current.contains(event.target)) {
        closeTooltip();
      }
    }

    function handleKeyDown(e: KeyboardEvent) {
      if (e) {
        if (e.keyCode === eventConstants.enterKeyCode || e.keyCode === eventConstants.escKeyCode) {
          closeTooltip();
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [closeTooltip]);

  return (
    <>
      <div
        ref={infoIconRef}
        className={cx(
          divStyle ? '' : styles.toolTipContainer,
          iconSizePx ? styles.hasIconSizePxOverride : ''
        )}
        style={divStyle}
      >
        <Button
          data-testid={dataTestId}
          aria-haspopup={true}
          onFocus={openTooltip}
          onBlur={closeTooltip}
          onMouseDown={handleOnMouseClick}
          type="button"
          customClass={
            iconSizePx
              ? styles.hasIconSizePxOverride
              : cx(styles.toolTipIcon, {
                  [classes.icon || '']: classes.icon,
                })
          }
          variant={variant}
        >
          {isCustomElement ? (
            customElement
          ) : (
            <TooltipIcon iconSizePx={iconSizePx} iconSrc={iconSrc} />
          )}
        </Button>
        <Popper
          isOpen={isOpen}
          anchorEl={infoIconRef.current}
          placement={placement}
          classes={{
            popper: cx(styles.toolTipPopperContainer, styles.popperPadding, {
              [classes.popper || '']: classes.popper,
            }),
            paper: styles.toolTipPopperPaper,
          }}
          elevation={4}
          isCorePricePadding={isCorePricePadding}
        >
          <PopperHeader
            classes={{ title: styles.toolTipPopperTitle, heading: styles.toolTipPopperHeading }}
            handleClose={closeTooltip}
            hideCloseIcon={hideCloseIcon}
          >
            <h1 className={azCommonStyles['az-button-text']}>
              <strong>{header}</strong>
            </h1>
          </PopperHeader>
          <PopperBody>
            <div
              className={azCommonStyles['az-body-2-regular']}
              dangerouslySetInnerHTML={(() => ({ __html: body }))()}
            />
          </PopperBody>
        </Popper>
      </div>
    </>
  );
};

export default InfoToolTip;
