/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import cx from 'classnames';
import azCommonStyles from '../../../theme/globals.module.scss';
import NextImage from '@/components/NextImage';
import styles from './styles.module.scss';
import { getWarrantyBadge } from '../../../utils/getWarrantyBadge';
import { Button } from '@/components/Button';
import { useMediaQuery } from '@/hooks/useMediaQuery';

type WarrantyBadgeClasses = {
  root?: string;
  button?: string;
  icon?: string;
  label?: string;
};

type Props = {
  warranty?: string;
  onClick?: () => void;
  classes?: WarrantyBadgeClasses;
};

const WarrantyBadge = ({ warranty, onClick, classes = {} }: Props) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  if (!warranty || warranty === '0 ano') {
    return null;
  }

  const maybeWarrantyNumber = warranty.split(' ')[0];
  const isWarrantyNumber = !isNaN(Number(maybeWarrantyNumber));
  const warrantyBadgeImgMetada = getWarrantyBadge(warranty);
  const warrantyMsg = isWarrantyNumber ? warranty.replace(maybeWarrantyNumber, '') : warranty;
  const badgeContents = (
    <>
      <NextImage
        aria-hidden={!isWarrantyNumber}
        aria-label={isWarrantyNumber ? maybeWarrantyNumber : undefined}
        src={warrantyBadgeImgMetada.src}
        alt={warrantyBadgeImgMetada.alt}
        width={isMobile ? 20 : 22}
        height={isMobile ? 20 : 25}
        className={cx(classes.icon)}
        data-testid="warranty-badge-icon"
      />
      <span
        className={cx(styles.warrantyBadgeText, classes.label)}
        data-testid="warranty-badge-label"
      >
        {warrantyMsg}
      </span>
    </>
  );

  return (
    <div
      data-testid="warranty-badge-container"
      className={cx(azCommonStyles['az-caption'], classes.root)}
    >
      {onClick ? (
        <Button
          variant="link"
          customClass={cx(styles.warrantyBadgeButton, classes.button)}
          onClick={onClick}
          data-testid="warranty-badge-btn"
        >
          {badgeContents}
        </Button>
      ) : (
        badgeContents
      )}
    </div>
  );
};

export default WarrantyBadge;
